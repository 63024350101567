import getUrl from './getUrl'
import qs from 'qs'

import attributeChanges from '../fixtures/attributeChanges.json'
import folders from '../fixtures/folders.json'
import reportDetail from '../fixtures/reportDetail.json'
import reports from '../fixtures/reports.json'
import reportsQuery from '../fixtures/reportsQuery.json'
import featuredObjects from '../fixtures/featuredObjects.json'
import tableResults from '../fixtures/tableResults.json'
import fieldReccomendations from '../fixtures/fieldRecommendations.json'
import validationRuleReccomendations from '../fixtures/validationRuleRecommendations.json'
import {
  ImportObjectMetadata,
  SalesforceField,
} from '../components/Objects/Import'
import { json } from 'stream/consumers'

const FIXTURE_ENV = process.env.NEXT_FIXTURE_ENV

type Args = Record<string, any>

export type GenerateGroupingsArgs = {
  prompt?: string
  objectName?: string
}

export type SessionPostArgs = {
  code: string
  appEnv: string
}

export type ValidateFieldNamesParams = {
  fieldNames: Array<string>
}

export type ValidateFunctionsParams = {
  functions: Array<string>
}

export type GetPageLayoutArgs = {
  objectName: string
  useCached?: boolean
}
export type GetPicklistValuesArgs = {
  objectName: string
  useCached?: boolean
}
export type CreateCustomFieldArgs = {
  objectName: string
  fieldType: string
  fieldName: string
  label: string
  defaultValue?: boolean
  description?: string
  helpText?: string
  pageLayoutNames: string[]
  values?: string[]
  relatedTo?: string
  formula?: string
  formulaFieldType?: string
  externalId?: boolean
  required?: boolean
}
export type EditCustomFieldArgs = {
  objectName: string
  fieldType: string
  fieldName: string
  newFieldName: string
  label: string
  description?: string
  helpText?: string
  defaultValue?: boolean
  formula?: string
  formulaFieldType?: string
  values?: string[]
  relatedTo?: string
  externalId?: boolean
  required?: boolean
}
export type DeleteCustomFieldArgs = {
  objectName: string
  fieldName: string
}
export type CreateValidationRuleArgs = {
  objectName: string
  name: string
  active: boolean
  description?: string
  formula: string
  errorDisplayField?: string
  errorMessage: string
}

export type DeleteValidationRuleArgs = {
  objectName: string
  name: string
}

export type UpdateValidationRuleArgs = {
  objectName: string
  name: string
  active: boolean
  description?: string
  formula: string
  errorDisplayField?: string
  errorMessage: string
  id?: string
}
export type GetObjectValidationRulesArgs = {
  objectName: string
}
export type GenerateValidationRuleArgs = {
  objectName: string
  prompt: string
}
export type UpdateAccountArgs = {
  description?: string
  tags?: Array<any>
}
export type GenerateCustomFieldArgs = {
  objectName: string
  prompt: string
  fieldType: string
  formula?: string
  only_description?: boolean
}
export type GenerateObjectArgs = {
  prompt: string
}

export type UpdateObjectArgs = {
  objectName: string
  id: string
  updatedField: string
}

export type DeleteObjectArgs = {
  objectName: string
}

export type CustomFieldRecommendationsArgs = {
  objectName: string
  objectLabel: string
  description?: string
}
export type GetCustomFieldArgs = {
  objectName: string
  customFieldName: string
}
export type GetCustomFieldDefinitionArgs = {
  objectName: string
  fieldNames: string[]
}
export type GetAllLookupFiltersProps = {
  objectName: string
  customFieldNames?: Array<any>
  useCached?: boolean
}
export type GetCustomFieldsArgs = {
  keyword?: string
}
export type GetFieldsArgs = {
  objectName: string
  showRollupsOnly?: boolean
  include_referenced_objects?: boolean
  disallowMultipleReferenceFields?: boolean
  useCached?: boolean
  include_help_text?: boolean
  team_id?: string
}
export type CreateObjectArgs = {
  fullName: string
  deployed: boolean
  label: string
  description?: string
}
export type GetSnapshotArgs = {
  objectType: string
  snapshotId?: string
}
export type CreateRecordArgs = {
  objectName: string
  fields: any
  formatReturnAsRelatedRecord?: boolean
  returnOpportunityContactRole?: boolean
}
export type FeatureObjectsArgs = {
  count?: number
}
export type RecommendedFieldsArgs = {
  object_name: string
}
export type RecommendedValidationRulessArgs = {
  object_name: string
}
export type GetObjectsArgs = {
  keyword?: string
}
export type CreateReportArgs = {
  query: string
  values?: any
}
export type ExportReportArgs = {
  query: string
}
export type GenerateReportQueryArgs = {
  objectName: string
  prompt: string
  errorContext?: string
  crossFilters: any[]
  selectOwnerAttributesOnly?: boolean
  excludedCustomInstructions?: Array<string>
}
export type CreateReportQueryArgs = {
  reportQuery: {
    name: string
    query: string
    description: string
    object_name: string
  }
}

export type UpdateReportQueryArgs = {
  reportQuery: {
    name: string
    description: string
    query: string
    object_name: string
  }
}

export type CrossFilterObjectsArgs = {
  objectName: string
  prompt: string
  excludedCustomInstructions?: Array<string>
}

export type DeleteReportQueryArgs = {
  id: string
}

export type GenerateCustomFieldFormulaArgs = {
  object_name: string
  prompt: string
  attributes: Array<Record<string, any>>
}

export type GetObjectRecommendationsArgs = {}

export type GetApexClassRelevantObjectsArgs = {
  description: string
}

export type UpdateFieldAccessArgs = {
  object: string
  fields: string[]
  profiles: string[]
  permissions_action: string
}

export type GenerateFlowArgs = {
  prompt: string
}

export type GetReportArgs = {
  objectName?: string
}

export type ReportDetailsArgs = {
  description: string
}

export type RetrieveApexClassArgs = {
  apexClassId: string
}

export type GetFlowGenerationJobArgs = {}

export type GetForecastGenerationJobArgs = {}

export type GenerateApexClassArgs = {
  description: string
  relevant_objects: string[]
}

export type DeployApexClassArgs = {
  modified_apex_classes: any
  modified_apex_triggers: any
  is_automation_trigger?: boolean | undefined
  apex_trigger_name?: string | undefined
}

export type DeployApexTriggerVersionArgs = {
  automation_trigger_id: string
  automation_trigger_version: number
}

export type DeleteApexTriggerArgs = {
  apex_trigger_name: string
}

export type ReportChartPropertiesArgs = {
  query: string
  table_header_names: string[]
}

export type Condition = {
  operator: string
  field_type: string
  field_value: string
}

export type CreateTriggerArgs = {
  condition_logic: string
  trigger_type: string
  object_api_name: string
  element_name: string
  trigger_event: string
  conditions: Array<Condition>
}

export type DataBulkUpdateArgs = {
  query: string
  object_name: string
  attribute_changes: any[]
}

export type AttributeChangesArgs = {
  query: string
  prompt: string
  column_names: string[]
  object_name: string
}

export type GetFlowsArgs = {}
export type CreateReportCreationLogsArgs = {
  report_query_id?: string
  description: string
  query: string
  object_name: string
}

export type CreateForecastArgs = {
  method: string
  categorize?: boolean
}

export type ExplainForecastArgs = {
  revenue_info_by_category: any
  predictions_by_category: any
}

export type ForecastScenarioArgs = {
  revenue_info_by_category: any
  predictions_by_category: any
  scenario_prompt: string
}

export type GetReportCreationLogsArgs = {
  page_number?: number
  per_page?: number
}
export type GetActivityArgs = {
  page_number?: number
  per_page?: number
}
export type CreateFlowArgs = {
  name: string
}
export type GetFlowArgs = {
  id: string | undefined | string[]
}

export type GetReportFolders = {}
export type CreateReportType = {
  object_name: string
}
export type DeployReportWithFormulaArgs = {
  object_name: string
  folder: string
  name: string
  report_type: string
  query: string
  chart_type?: string
  groupings?: {
    rows: Array<Record<string, string>>
    columns: Array<Record<string, string>>
  }
  formulas?: Array<Record<string, string>>
}

export type DeployReportArgs = {
  object_name: string
  folder: string
  name: string
  report_type: string
  query: string
  chart_type?: string
}

export type DeleteReportArgs = {
  salesforce_id: string
}

export type GetReportDeploymentStatus = {
  deploy_id: string
  is_flow?: boolean
}

export type GetBulkFieldsDeploymentStatus = {
  deploy_id: string
}

export type GetApexClassDeploymentStatusArgs = {
  deploy_id: string
  is_created?: boolean
  is_automation_trigger?: boolean
  apex_trigger_code?: string
  apex_trigger_name?: string
}

export type GetApexClassesArgs = {}

export type InterpretApexClassArgs = {
  apex_code: string
}

export type InterpretFlowErrorArgs = {
  flow_error: string
}

export type UpdateFlowArgs = {
  id: string | undefined | string[]
  flow_elements: any[]
  flow_connections: any[]
  formulas: any[]
}

export type DeployFlowArgs = {
  id: string | undefined | string[]
}

export type RemoveFlowArgs = {
  salesforce_id: string
  name?: string | undefined
  id?: string | undefined
  activity_id?: string | undefined
}

export type GenerateRelatedReportArgs = {
  object_name: string
  query: string
  description: string
}

export type GetJobStatusArgs = {
  job_id: string
}

export type DeployGroupingAndFormulas = {
  groupings: Array<string>
  formulas: Record<string, string>
}

export type FormulaParams = {
  reportCreationLogId?: string
  query?: string
}

export type Grouping = {
  dateGranularity?: string | null
  name?: string | null
  sortAggregate?: string | null
  sortOrder?: string | null
  reportType?: {
    label?: string | null
    type?: string | null
  }
}

export type DryRunReportArgs = {
  groupingsAcross: Array<Grouping>
  groupingsDown: Array<Grouping>
  customSummaryFormula: Record<
    string,
    {
      acrossGroup?: string | null
      acrossGroupType?: string | null
      decimalPlaces?: number
      description?: string | null
      downGroupType?: string | null
      formula: string
      formulaType: string
      label: string
    }
  >
} & any

export type RecorrectArgs = DryRunReportArgs & {
  errorMessage?: string
  reportFormulas?: Array<any>
}

export type BulkCreateCustomFieldsArgs = {
  fields_to_create: Array<SalesforceField>
  object_metadata: ImportObjectMetadata
}

export type GetObjectsMetadataArgs = {
  id: string
}

export type FetchColumnsArgs = {
  reportIds: Array<string>
}

export type CreateCombinedReportArgs = {
  firstReportId: string
  secondReportId: string
  firstReportColumn: string
  secondReportColumn: string
  combinedReportName: string
  joinType: 'left' | 'inner' | 'full'
}

export type GetCombinedReportArgs = { reportId: string }
export type FetchCombinedReportByIdArgs = GetCombinedReportArgs

export type SaveReportChangesArgs = {
  changesMap: string
} & GetCombinedReportArgs

export type CreateCustomColumnArgs = {
  columnName: string
  columnLabel: string
  columnDataType: string
  columnConfiguration: string
} & GetCombinedReportArgs

export type GenerateColumnArgs = {
  columnConfiguration: string
  columnName: string
  allColumns: Array<any>
} & GetCombinedReportArgs

export type DeleteCombinedReportArgs = GetCombinedReportArgs

export type CreateCombinedReportScheduledRunArgs = {
  cronExpression: string
} & GetCombinedReportArgs

export type CombinedReportRunToggleArgs = GetCombinedReportArgs
export type GetReportRunArgs = {
  runDetails: string
} & GetCombinedReportArgs

export type RollupFieldObjectNameArgs = {
  objectName: string
}
export type CreateRollupFieldsArgs = {
  objectFieldApiName: string
  childObjectFieldApiName: string
  childObjectName?: string
  childObjectLinkedFieldName?: string
  childObjectRelationshipName?: string
  fieldType: string
  formula: 'COUNT' | 'AVG' | 'SUM' | 'MIN' | 'MAX'
  filters?: {
    parent?: {
      filters: Array<any>
    }
    child?: {
      filters: Array<any>
    }
  }
} & RollupFieldObjectNameArgs

export type UpdateRollupConfigurationArgs = {
  rollupId: string
  childObjectFieldApiName?: string
  childObjectName?: string
  childObjectLinkedFieldName?: string
  childObjectRelationshipName?: string
  fieldType?: string
  formula?: 'COUNT' | 'AVG' | 'SUM' | 'MIN' | 'MAX'
  filters?: {
    parent?: {
      filters: Array<any>
    }
    child?: {
      filters: Array<any>
    }
  }
} & RollupFieldObjectNameArgs

export type FetchRollupConfigurationArgs = {
  rollupId: string
} & RollupFieldObjectNameArgs

export type UpdateRollupValueArgs = FetchRollupConfigurationArgs

export type CreateRollupScheduledRunArgs = {
  cronExpression: string
  rollupId: string
}

export type FetchRollupScheduledRunArgs = {
  rollupId: string
}

export type FetchRollupAlertDefinitionArgs = FetchRollupScheduledRunArgs

export type CreateRollupAlertArgs = {
  conditions: any
  operator: string
  name: string
  type: string
  slack_notification_enabled: boolean
  slack_notification_settings?: any
  objectName: string
}

export type UpdateRollupAlertArgs = CreateRollupAlertArgs & {
  alertDefinitionId: string
}

export type DeleteRollupAlertsArgs = {
  alertDefinitionId: string
}

export type GetAutomationsArgs = {}

export type HandleConditionalAssignmentRulesArgs = {}

export type CreateRecordTypeArgs = {
  objectName: string
  recordTypeName: string
  description?: string
  businessProcess?: string
}

export type RevertAssignmentsTypeArgs = {
  assignmentUploadId: string
}
export class Api {
  static flows = {
    generateFlow: async (args: GenerateFlowArgs) => {
      return Api.post(`${getUrl()}/flows/generate`, args).then(e => {
        return e.json()
      })
    },
    getFlows: async (args: GetFlowsArgs) => {
      return Api.get(`${getUrl()}/flows`, args).then(e => e.json())
    },
    createFlow: async (args: CreateFlowArgs) => {
      return Api.post(`${getUrl()}/flows`, args).then(e => e.json())
    },
    interpret: async (args: InterpretFlowErrorArgs) => {
      return Api.post(`${getUrl()}/flow_errors/interpret`, args).then(e =>
        e.json(),
      )
    },
    getFlow: async (args: GetFlowArgs) => {
      return Api.get(`${getUrl()}/flows/${args.id}`, args).then(e => e.json())
    },
    updateFlow: async (args: UpdateFlowArgs) => {
      return Api.put(`${getUrl()}/flows/${args.id}`, args).then(e => e.json())
    },
    deployFlow: async (args: DeployFlowArgs) => {
      return Api.post(`${getUrl()}/flows/${args.id}/deploy`, args).then(e =>
        e.json(),
      )
    },
    deleteFlow: async (args: RemoveFlowArgs) => {
      return Api.delete(`${getUrl()}/flows/remove`, args).then(e => e.json())
    },
    importFlow: async (args: any) => {
      return Api.post(`${getUrl()}/flows/import`, args).then(e => e.json())
    },
    marketplaceDemoImport: async (args: any) => {
      return Api.post(`${getUrl()}/flows/marketplace_demo_import`, args).then(
        e => e.json(),
      )
    },
  }

  static customInstructions = {
    getInstructions: async () => {
      return Api.get(`${getUrl()}/custom_instructions`).then(e => e.json())
    },
    updateInstruction: async (args: {
      id: string
      instruction?: string
      instructionContext?: string
    }) => {
      return Api.patch(`${getUrl()}/custom_instructions/${args.id}`, args).then(
        e => e.json(),
      )
    },
    createInstruction: async (args: {
      instruction?: string
      instructionContext?: string
    }) => {
      return Api.post(`${getUrl()}/custom_instructions`, args).then(e =>
        e.json(),
      )
    },
    deleteInstruction: async (args: { id: string }) => {
      return Api.delete(
        `${getUrl()}/custom_instructions/${args.id}`,
        args,
      ).then(e => e.json())
    },
  }

  static automations = {
    getAutomations: async () => {
      return Api.get(`${getUrl()}/automation_triggers`).then(e => e.json())
    },
    fetchTrigger: async (args: { triggerId: string }) => {
      return Api.get(`${getUrl()}/automation_triggers/trigger`, args).then(e =>
        e.json(),
      )
    },
    updateTags: async (args: { tags: Array<string>; name: string }) => {
      return Api.post(`${getUrl()}/automation_triggers/update_tags`, args).then(
        e => e.json(),
      )
    },
    tags: async () => {
      return Api.get(`${getUrl()}/automation_triggers/tags`).then(e => e.json())
    },
  }

  static flowGenerationJobs = {
    getFlowGenerationJob: async (
      id: string,
      args: GetFlowGenerationJobArgs,
    ) => {
      return Api.get(`${getUrl()}/flow_generation_jobs/${id}`, args).then(e => {
        return e.json()
      })
    },
  }

  static forecastGenerationJobs = {
    getForecastGenerationJob: async (
      id: string,
      args: GetForecastGenerationJobArgs,
    ) => {
      return Api.get(`${getUrl()}/forecast_generation_jobs/${id}`, args).then(
        e => {
          return e.json()
        },
      )
    },
  }

  static home = {
    insights: async (args: any) => {
      return Api.get(`${getUrl()}/home/insights`, args).then(e => e.json())
    },
  }

  static documentation = {
    getDocumentation: async (args: any) => {
      return Api.get(`${getUrl()}/documentation`, args).then(e => e.json())
    },
    showDocumentation: async (id: any) => {
      return Api.get(`${getUrl()}/documentation/view/${id}`).then(e => e.json())
    },
  }

  static assignmentUploads = {
    getAssignmentUploads: async (args: any) => {
      return Api.get(`${getUrl()}/assignment_uploads`, args).then(e => e.json())
    },
    getAssignmentUpload: async (id: string) => {
      return Api.get(`${getUrl()}/assignment_uploads/${id}`).then(e => e.json())
    },
    assignments: {
      getAssignments: async (assignmentUploadId: string, args: any) => {
        return Api.getV2(
          `${getUrl()}/assignment_uploads/${assignmentUploadId}/assignments`,
          args,
        ).then(e => e.json())
      },
      getSavedAssignments: async (args: any) => {
        return Api.getV2(
          `${getUrl()}/salesforce/assignments/saved_assignments`,
          args,
        ).then(e => e.json())
      },
      getAllIds: async (assignmentUploadId: string, args: any) => {
        return Api.getV2(
          `${getUrl()}/assignment_uploads/${assignmentUploadId}/assignments/index_all`,
          args,
        ).then(e => e.json())
      },
      assignmentRules: async (args: any) => {
        return Api.post(
          `${getUrl()}/salesforce/assignments/handle_conditional_assignments`,
          args,
          false,
        ).then(e => e.json())
      },
      saveAssignmentRules: async (args: any) => {
        return Api.post(
          `${getUrl()}/salesforce/assignments/create_saved_assignment`,
          args,
          false,
        ).then(e => e.json())
      },
      updateAssignmentRules: async (args: any) => {
        return Api.patch(
          `${getUrl()}/salesforce/assignments/update_saved_assignment`,
          args,
          false,
        ).then(e => e.json())
      },
      updateAssignmentCadence: async (args: any) => {
        return Api.patch(
          `${getUrl()}/salesforce/assignments/update_saved_assignment_cadence`,
          args,
          false,
        ).then(e => e.json())
      },
      deleteSavedAssignment: async (args: any) => {
        return Api.delete(
          `${getUrl()}/salesforce/assignments/delete_saved_assignment`,
          args,
        ).then(e => e.json())
      },
      fetchSavedAssignments: async (args: { id: string }) => {
        return Api.get(
          `${getUrl()}/salesforce/assignments/saved_assignment`,
          args,
        ).then(e => e.json())
      },
      revertAssignments: async (args: RevertAssignmentsTypeArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/assignments/revert_assignments`,
          args,
          true,
        ).then(e => e.json())
      },
    },
  }

  static teams = {
    getUserTeams: async (args: any) => {
      return Api.get(`${getUrl()}/teams/lists/user_teams`, args).then(e =>
        e.json(),
      )
    },
    getTeams: async (args: any) => {
      return Api.get(`${getUrl()}/teams`, args).then(e => e.json())
    },
    createTeam: async (args: any) => {
      return Api.post(`${getUrl()}/teams`, args).then(e => e.json())
    },
    addSubTeams: async (args: any) => {
      return Api.post(`${getUrl()}/teams/add_subteams`, args).then(e =>
        e.json(),
      )
    },
    setManagerQuota: async (args: any) => {
      return Api.post(`${getUrl()}/teams/set_manager_quota`, args).then(e =>
        e.json(),
      )
    },
    fetchTeam: async (args: any) => {
      return Api.get(`${getUrl()}/teams/${args.id}`, args).then(e => e.json())
    },
    members: {
      createMembers: async (teamId: string, args: any) => {
        return Api.post(`${getUrl()}/teams/${teamId}/members`, args).then(e =>
          e.json(),
        )
      },
      updateMember: async (teamId: string, memberId: string, args: any) => {
        return Api.patch(
          `${getUrl()}/teams/${teamId}/members/${memberId}`,
          args,
        ).then(e => e.json())
      },
      deleteMember: async (teamId: string, memberId: string) => {
        return Api.delete(
          `${getUrl()}/teams/${teamId}/members/${memberId}`,
        ).then(e => e.json())
      },
    },
    forecasts: {
      getForecasts: async (teamId: string, args: any) => {
        return Api.get(`${getUrl()}/teams/${teamId}/forecasts`, args).then(e =>
          e.json(),
        )
      },
      lastPeriodBookings: async (teamId: string, args: any) => {
        return Api.get(
          `${getUrl()}/teams/${teamId}/forecasts/last_period_bookings`,
          args,
        ).then(e => e.json())
      },
    },
    quotas: {
      getQuotas: async (teamId: string, args: any) => {
        return Api.get(`${getUrl()}/teams/${teamId}/quotas`, args).then(e =>
          e.json(),
        )
      },
      quotasForTeam: async (teamId: string, args?: any) => {
        return Api.get(`${getUrl()}/teams/${teamId}/quotas/list`, args).then(
          e => e.json(),
        )
      },
    },
  }

  static forecastRules = {
    getForecastsRules: async () => {
      return Api.get(`${getUrl()}/forecast_rules`).then(e => e.json())
    },
    createForecastRules: async (args: {
      operator: string
      conditions: Array<any>
      name: string
      stageModifiers: { [key: string]: string | number }
    }) => {
      return Api.post(`${getUrl()}/forecast_rules`, args).then(e => e.json())
    },
    updateForecastRule: async (args: {
      ruleId: string
      conditions: Array<any>
      operator: string
      name: string
      stageModifiers: { [key: string]: string | number }
    }) => {
      return Api.put(`${getUrl()}/forecast_rules/${args.ruleId}`, args).then(
        e => e.json(),
      )
    },
    deleteForecastRule: async (args: { ruleId: string }) => {
      return Api.delete(`${getUrl()}/forecast_rules/${args.ruleId}`, args).then(
        e => e.json(),
      )
    },
  }

  static statusUpdate = {
    // Fetch validation rules as XML
    getValidationRules: async (args: any) => {
      return Api.get(
        `${getUrl()}/salesforce/status_update/fetch_validation_rules`,
        args,
      ).then(e => e.json())
    },

    // Fetch process flows as XML
    getProcessFlows: async (args: any) => {
      return Api.get(
        `${getUrl()}/salesforce/status_update/fetch_process_flows`,
        args,
      ).then(e => e.json())
    },

    // Fetch triggers as XML
    getTriggers: async (args: any) => {
      return Api.get(
        `${getUrl()}/salesforce/status_update/fetch_triggers`,
        args,
      ).then(e => e.json())
    },

    deployChanges: async (args: any) => {
      return Api.post(
        `${getUrl()}/salesforce/status_update/deploy_changes`,
        args,
      ).then(e => e.json())
    },

    pollProcessFlows: async (args: any) => {
      return Api.get(
        `${getUrl()}/salesforce/status_update/poll_process_flows`,
        args,
      ).then(e => e.json())
    },
  }

  static alerts = {
    getAlertNotifications: async (args: any) => {
      return Api.get(`${getUrl()}/deal_alerts/notifications`, args).then(e =>
        e.json(),
      )
    },
    getAlertDefinitions: async (args: any) => {
      return Api.get(`${getUrl()}/deal_alerts/alert_definitions`, args).then(
        e => e.json(),
      )
    },
    createAlert: async (args: any) => {
      return Api.post(`${getUrl()}/deal_alerts/`, args).then(e => e.json())
    },
    updateAlert: async (args: any) => {
      return Api.patch(
        `${getUrl()}/deal_alerts/update_alert_definition`,
        args,
      ).then(e => e.json())
    },
    deleteAlert: async (args: any) => {
      return Api.delete(
        `${getUrl()}/deal_alerts/delete_alert_definition`,
        args,
      ).then(e => e.json())
    },
    fetchRollupAlertDefinitions: async (args: any) => {
      return Api.get(`${getUrl()}/rollup_fields/alerts`, args).then(e =>
        e.json(),
      )
    },
    createRollupAlert: async (args: CreateRollupAlertArgs) => {
      return Api.post(`${getUrl()}/rollup_fields/create_alert`, args).then(e =>
        e.json(),
      )
    },
    updateRollupAlert: async (args: UpdateRollupAlertArgs) => {
      return Api.put(`${getUrl()}/rollup_fields/update_alert`, args).then(e =>
        e.json(),
      )
    },
    deleteRollupAlert: async (args: DeleteRollupAlertsArgs) => {
      return Api.delete(`${getUrl()}/rollup_fields/delete_alert`, args).then(
        e => e.json(),
      )
    },
    getRollupObjects: async () => {
      return Api.get(`${getUrl()}/deal_alerts/all_rollup_objects`).then(e =>
        e.json(),
      )
    },
  }

  static airtable = {
    authenticate: async (args: any) => {
      return Api.post(`${getUrl()}/airtable/authenticate`, args).then(e =>
        e.json(),
      )
    },
    getBases: async (args: any) => {
      return Api.get(`${getUrl()}/airtable/bases`, args).then(e => e.json())
    },
    getBaseSchema: async (args: any) => {
      return Api.get(`${getUrl()}/airtable/base_schema`, args).then(e =>
        e.json(),
      )
    },
    getTableFields: async (args: any) => {
      return Api.get(`${getUrl()}/airtable/table_fields`, args).then(e =>
        e.json(),
      )
    },
  }

  static hubspot = {
    authenticate: async (args: any) => {
      return Api.post(`${getUrl()}/hubspot/authenticate`, args).then(e =>
        e.json(),
      )
    },
    getObjectFields: async (args: any) => {
      return Api.get(`${getUrl()}/hubspot/object_fields`, args).then(e =>
        e.json(),
      )
    },
    getCustomObjectFields: async (args: any) => {
      return Api.get(`${getUrl()}/hubspot/custom_object_fields`, args).then(e =>
        e.json(),
      )
    },
    getCustomObjects: async (args: any) => {
      return Api.get(`${getUrl()}/hubspot/custom_objects`, args).then(e =>
        e.json(),
      )
    },
  }

  static onboarding = {
    createSalesforceTransfer: async (args: any) => {
      return Api.post(`${getUrl()}/salesforce_transfer`, args).then(e =>
        e.json(),
      )
    },
    createTableMapping: async (args: any) => {
      return Api.post(
        `${getUrl()}/salesforce_transfer/create_table_mapping`,
        args,
      ).then(e => e.json())
    },
    updateTableMapping: async (args: any) => {
      return Api.patch(
        `${getUrl()}/salesforce_transfer/update_table_mapping`,
        args,
      ).then(e => e.json())
    },
    deleteTableMapping: async (args: any) => {
      return Api.delete(
        `${getUrl()}/salesforce_transfer/delete_table_mapping`,
        args,
      ).then(e => e.json())
    },
    updateFieldMappings: async (args: any) => {
      return Api.patch(
        `${getUrl()}/salesforce_transfer/update_field_mappings`,
        args,
      ).then(e => e.json())
    },
    getSalesforceTransfer: async (args: any) => {
      return Api.get(`${getUrl()}/salesforce_transfer/fetch`, args).then(e =>
        e.json(),
      )
    },
    getFieldMappingSuggestions: async (args: any) => {
      return Api.get(
        `${getUrl()}/salesforce_transfer/field_mapping_suggestions`,
        args,
      ).then(e => e.json())
    },
    beginTransfer: async (args: any) => {
      return Api.post(
        `${getUrl()}/salesforce_transfer/begin_transfer`,
        args,
      ).then(e => e.json())
    },
    getTransferStatus: async (args: any) => {
      return Api.get(
        `${getUrl()}/salesforce_transfer/transfer_status`,
        args,
      ).then(e => e.json())
    },
    getFailedImportRecords: async (args: any) => {
      return Api.get(
        `${getUrl()}/salesforce_transfer/failed_import_records_csv`,
        args,
      ).then(e => e?.blob())
    },
  }

  static slack = {
    authenticate: async (args: any) => {
      return Api.post(`${getUrl()}/slack/authenticate`, args).then(e =>
        e.json(),
      )
    },
    getUsers: async (args: any) => {
      return Api.get(`${getUrl()}/slack/users`, args).then(e => e.json())
    },
    getChannels: async (args: any) => {
      return Api.get(`${getUrl()}/slack/channels`, args).then(e => e.json())
    },
  }

  static listViews = {
    createListView: async (args: any) => {
      return Api.post(`${getUrl()}/list_view`, args).then(e => e.json())
    },
    getListViews: async (args: any) => {
      return Api.get(`${getUrl()}/list_view/fetch`, args).then(e => e.json())
    },
    updateListView: async (args: any) => {
      return Api.patch(`${getUrl()}/list_view/update`, args).then(e => e.json())
    },
    deleteListView: async (args: any) => {
      return Api.delete(`${getUrl()}/list_view/delete`, args).then(e =>
        e.json(),
      )
    },
    fetchListObjects: async () => {
      return Api.get(`${getUrl()}/list_view/fetch_objects`).then(e => e.json())
    },
    newFields: async () => {
      return Api.put(`${getUrl()}/list_view/new_fields`, {}).then(e => e.json())
    },
  }

  static forecastOverrides = {
    create: async (args: any) => {
      return Api.post(`${getUrl()}/forecast_override`, args).then(e => e.json())
    },
    fetch: async (args: any) => {
      return Api.get(`${getUrl()}/forecast_override`, args).then(e => e.json())
    },
    update: async (args: any) => {
      return Api.patch(`${getUrl()}/forecast_override/${args.id}`, args).then(
        e => e.json(),
      )
    },
    delete: async (args: any) => {
      return Api.delete(`${getUrl()}/forecast_override/${args.id}`, args).then(
        e => e.json(),
      )
    },
  }

  static pipelineSavedFilters = {
    // Create a new filter
    create: async (args: any) => {
      return Api.post(`${getUrl()}/pipeline_saved_filters`, args).then(e =>
        e.json(),
      )
    },

    // Delete a filter
    destroy: async (filterId: any) => {
      return Api.delete(`${getUrl()}/pipeline_saved_filters/${filterId}`).then(
        e => {
          if (e.status === 204) {
            // HTTP status code for 'No Content'
            return null // No content to parse
          }
          return e.json()
        },
      )
    },

    // Get all saved filters for the current user
    getFilters: async (args: any) => {
      return Api.get(`${getUrl()}/pipeline_saved_filters/filters`, {
        params: { object_type: args.object_type },
      }).then(e => e.json())
    },

    update: async (filterId: string, args: any) => {
      return Api.patch(
        `${getUrl()}/pipeline_saved_filters/${filterId}`,
        args,
      ).then(e => e.json())
    },

    updateOneFilter: async (filterId: string, args: any) => {
      return Api.patch(
        `${getUrl()}/pipeline_saved_filters/${filterId}/update_one_filter`,
        args,
      ).then(e => e.json())
    },
  }

  static pipelineInspectionFilters = {
    getFilters: async (args: any) => {
      return Api.get(
        `${getUrl()}/pipeline_inspection_filters/filters`,
        args,
      ).then(e => e.json())
    },
    createFilter: async (args: any) => {
      return Api.post(`${getUrl()}/pipeline_inspection_filters`, args).then(e =>
        e.json(),
      )
    },
    deleteFilter: async (filterId: string) => {
      return Api.delete(
        `${getUrl()}/pipeline_inspection_filters/${filterId}`,
      ).then(e => e.json())
    },
  }

  static forecasts = {
    getForecasts: async (args: any) => {
      return Api.get(`${getUrl()}/forecasts`, args).then(e => e.json())
    },
    latestForecast: async (args: any) => {
      return Api.get(`${getUrl()}/forecasts/latest`, args).then(e => e.json())
    },
    createForecast: async (args: any) => {
      return Api.post(`${getUrl()}/forecasts`, args, false).then(e => e.json())
    },
    showForecast: async (id: string) => {
      return Api.get(`${getUrl()}/forecasts/${id}`).then(e => e.json())
    },
    updateForecast: async (id: string, args: any) => {
      return Api.patch(`${getUrl()}/forecasts/${id}`, args, false).then(e =>
        e.json(),
      )
    },
    teamAggregates: async (args: any) => {
      return Api.get(`${getUrl()}/forecasts/team_aggregates`, args).then(e =>
        e.json(),
      )
    },
    latestOverrides: async (args: any) => {
      return Api.get(`${getUrl()}/forecasts/latest_overrides`, args).then(e =>
        e.json(),
      )
    },
    totalProjections: async (args: any) => {
      return Api.get(`${getUrl()}/forecasts/total_projections`, args).then(e =>
        e.json(),
      )
    },
    dealActivity: async (args: any) => {
      return Api.get(`${getUrl()}/forecasts/deal_activity`, args).then(e =>
        e.json(),
      )
    },
    quotaAggregates: async (args: any) => {
      return Api.get(`${getUrl()}/forecasts/quota_aggregates`, args).then(e =>
        e.json(),
      )
    },
    pipelineWaterfallValues: async (args: any) => {
      return Api.get(
        `${getUrl()}/forecasts/pipeline_waterfall_values`,
        args,
      ).then(e => e.json())
    },
    pipelineInspectionRecords: async (args: any) => {
      return Api.get(
        `${getUrl()}/forecasts/pipeline_inspection_records`,
        args,
      ).then(e => e.json())
    },
    pipelineInspectionRecordsCSV: async (args: any) => {
      return Api.get(
        `${getUrl()}/forecasts/pipeline_inspection_records_csv`,
        args,
      ).then(e => e.blob())
    },
    pipelineInspectionCSV: async (args: any) => {
      return Api.post(
        `${getUrl()}/forecasts/pipeline_inspection_csv`,
        args,
      ).then(e => e.blob())
    },
    quarterDates: async (args: any) => {
      return Api.get(`${getUrl()}/forecasts/quarter_dates`, args).then(e =>
        e.json(),
      )
    },
    forecastPeriodType: async (args: any) => {
      return Api.get(`${getUrl()}/forecasts/forecast_period_type`, args).then(
        e => e.json(),
      )
    },
    deals: {
      getDeals: async (forecastId: string, args: any) => {
        return Api.get(`${getUrl()}/forecasts/${forecastId}/deals`, args).then(
          e => e.json(),
        )
      },
      export: async (forecastId: string, args: any) => {
        return Api.get(
          `${getUrl()}/forecasts/${forecastId}/deals/export`,
          args,
        ).then(e => e.json())
      },
    },
  }

  static autoSavedForecasts = {
    getLatest: async (args: any) => {
      return Api.get(
        `${getUrl()}/auto_saved_forecasts/fetch_latest`,
        args,
      ).then(e => e.json())
    },
    createForecast: async (args: any) => {
      return Api.post(`${getUrl()}/auto_saved_forecasts`, args, false).then(e =>
        e.json(),
      )
    },
    updateForecast: async (id: string, args: any) => {
      return Api.patch(
        `${getUrl()}/auto_saved_forecasts/${id}`,
        args,
        false,
      ).then(e => e.json())
    },
    deals: {
      getDeals: async (args: any) => {
        return Api.get(
          `${getUrl()}/auto_saved_forecasts/forecast_deals`,
          args,
        ).then(e => e.json())
      },
    },
  }

  static forecastDeals = {
    getAggregates: async (args: any) => {
      return Api.getV2(`${getUrl()}/forecast_deals/aggregates`, args).then(e =>
        e.json(),
      )
    },
    forecastDealsCSV: async (args: any) => {
      return Api.getV2(`${getUrl()}/forecast_deals/export`, args).then(e =>
        e.blob(),
      )
    },
    getSummary: async (args: any) => {
      return Api.getV2(`${getUrl()}/forecast_deals/summary`, args).then(e =>
        e.json(),
      )
    },
  }

  static forecastStageMappings = {
    getForecastStageMappings: async (args: any) => {
      return Api.get(`${getUrl()}/forecast_stage_mappings`, args).then(e =>
        e.json(),
      )
    },
    categories: async (args: any) => {
      return Api.get(
        `${getUrl()}/forecast_stage_mappings/categories`,
        args,
      ).then(e => e.json())
    },
    update: async (args: { forecastStageMappings: Array<any> }) => {
      return Api.put(
        `${getUrl()}/forecast_stage_mappings/bulk_update`,
        args,
      ).then(e => e.json())
    },
  }

  static forecastCategories = {
    getForecastCategories: async (args: any) => {
      return Api.get(`${getUrl()}/forecast_categories`, args).then(e =>
        e.json(),
      )
    },
  }

  static defaultFieldSets = {
    fetch: async (args: any) => {
      return Api.get(`${getUrl()}/default_record_type_field_sets`, args).then(
        e => e.json(),
      )
    },
    create: async (args: any) => {
      return Api.post(
        `${getUrl()}/default_record_type_field_sets`,
        args,
        false,
      ).then(e => e.json())
    },
    update: async (args: any) => {
      const { id, ...params } = args
      return Api.patch(
        `${getUrl()}/default_record_type_field_sets/${id}`,
        params,
        false,
      ).then(e => e.json())
    },

    delete: async (args: any) => {
      return Api.delete(
        `${getUrl()}/default_record_type_field_sets/${args.id}`,
        args,
      ).then(e => e.json())
    },
  }

  static opportunityStageKeyFields = {
    fetch: async (args: any) => {
      return Api.get(`${getUrl()}/opportunity_stage_key_fields`, args).then(e =>
        e.json(),
      )
    },
    update: async (args: { opportunityStageKeyFields: Array<any> }) => {
      return Api.put(
        `${getUrl()}/opportunity_stage_key_fields/bulk_update`,
        args,
      ).then(e => e.json())
    },
  }

  static starredRecords = {
    fetch: async (args: any) => {
      return Api.get(`${getUrl()}/starred_records`, args).then(e => e.json())
    },
    update: async (args: any) => {
      return Api.patch(
        `${getUrl()}/starred_records/${args.salesforce_id}`,
        args,
        false,
      ).then(e => e.json())
    },
  }

  static forecastKanbanAggregateField = {
    get: async (args: any) => {
      return Api.get(`${getUrl()}/forecast_kanban_aggregate_fields`, args).then(
        e => e.json(),
      )
    },
    update: async (args: { kanban_aggregate_fields: Array<any> }) => {
      return Api.patch(
        `${getUrl()}/forecast_kanban_aggregate_fields/bulk_update`,
        args,
      ).then(e => e.json())
    },
  }

  static forecastDealKeyFields = {
    get: async (args: any) => {
      return Api.get(`${getUrl()}/forecast_deal_key_fields`, args).then(e =>
        e.json(),
      )
    },
    update: async (args: { key_fields: Array<any> }) => {
      return Api.patch(
        `${getUrl()}/forecast_deal_key_fields/bulk_update`,
        args,
      ).then(e => e.json())
    },
  }

  static forecastDealTypeAmountFields = {
    getForecastDealTypeAmountFields: async (args: any) => {
      return Api.get(`${getUrl()}/forecast_deal_type_amount_fields`, args).then(
        e => e.json(),
      )
    },
    update: async (args: { deal_type_amounts: Array<any> }) => {
      return Api.patch(
        `${getUrl()}/forecast_deal_type_amount_fields/bulk_update`,
        args,
      ).then(e => e.json())
    },
  }

  static forecastCustomRollups = {
    getForecastCustomRollups: async () => {
      return Api.get(`${getUrl()}/forecast_custom_rollups`).then(e => e.json())
    },
    create: async (args: any) => {
      return Api.post(`${getUrl()}/forecast_custom_rollups/`, args, false).then(
        e => e.json(),
      )
    },
    delete: async (args: any) => {
      return Api.delete(`${getUrl()}/forecast_custom_rollups/${args.id}`).then(
        e => e.json(),
      )
    },
    update: async (args: any) => {
      return Api.patch(
        `${getUrl()}/forecast_custom_rollups/${args.id}`,
        args,
        false,
      ).then(e => e.json())
    },
  }

  static scorecardMetrics = {
    getScorecardMetrics: async (args: any) => {
      return Api.get(`${getUrl()}/scorecard_metrics`, args).then(e => e.json())
    },
    updateScorecardMetrics: async (args: any) => {
      return Api.post(`${getUrl()}/scorecard_metrics/bulk_update`, args).then(
        e => e.json(),
      )
    },
    getCoastScorecardMetrics: async (args: any) => {
      return Api.get(
        `${getUrl()}/coast_scorecard_metrics/scorecards`,
        args,
      ).then(e => e.json())
    },
    getCoastScorecardMetricGoals: async (args: any) => {
      return Api.get(`${getUrl()}/coast_scorecard_metrics/goals`, args).then(
        e => e.json(),
      )
    },
    updateCoastScorecardMetricGoals: async (args: any) => {
      return Api.patch(
        `${getUrl()}/coast_scorecard_metrics/update_goals`,
        args,
      ).then(e => e.json())
    },
    getFundrScorecardMetrics: async (args: any) => {
      return Api.get(
        `${getUrl()}/fundr_scorecard_metrics/scorecards`,
        args,
      ).then(e => e.json())
    },
    getFundrScorecardMetricGoals: async (args: any) => {
      return Api.get(`${getUrl()}/fundr_scorecard_metrics/goals`, args).then(
        e => e.json(),
      )
    },
    updateFundrScorecardMetricGoals: async (args: any) => {
      return Api.patch(
        `${getUrl()}/fundr_scorecard_metrics/update_goals`,
        args,
      ).then(e => e.json())
    },
  }

  static scorecardMetricAlerts = {
    createAlert: async (args: any) => {
      return Api.post(`${getUrl()}/scorecard_metric_alerts/`, args).then(e =>
        e.json(),
      )
    },
    getAlerts: async (args: any) => {
      return Api.get(`${getUrl()}/scorecard_metric_alerts/alerts`, args).then(
        e => e.json(),
      )
    },
    deleteAlert: async (args: any) => {
      return Api.delete(
        `${getUrl()}/scorecard_metric_alerts/delete_alert`,
        args,
      ).then(e => e.json())
    },
    updateAlert: async (args: any) => {
      return Api.patch(
        `${getUrl()}/scorecard_metric_alerts/update_alert`,
        args,
      ).then(e => e.json())
    },
  }

  static quotas = {
    getLatestQuota: async (args: any) => {
      return Api.get(`${getUrl()}/quotas/find`, args).then(e => e.json())
    },
  }

  static salesforceOrgMembers = {
    forecasts: {
      getLastPeriodBookings: async (
        salesforceOrgMemberId: string,
        args: any,
      ) => {
        return Api.get(
          `${getUrl()}/salesforce_org_members/${salesforceOrgMemberId}/forecasts/last_period_bookings`,
          args,
        ).then(e => e.json())
      },
      getLatest: async (salesforceOrgMemberId: string, args: any) => {
        return Api.get(
          `${getUrl()}/salesforce_org_members/${salesforceOrgMemberId}/forecasts/latest`,
          args,
        ).then(e => e.json())
      },
    },
    quotas: {
      getLatestQuota: async (salesforceOrgMemberId: string, args: any) => {
        return Api.get(
          `${getUrl()}/salesforce_org_members/${salesforceOrgMemberId}/quotas/find`,
          args,
        ).then(e => e.json())
      },
      create: async (args: any) => {
        return Api.post(
          `${getUrl()}/salesforce_org_members/${
            args.salesforce_org_member_id
          }/quotas`,
          args,
        ).then(e => e.json())
      },
      update: async (args: any) => {
        return Api.patch(
          `${getUrl()}/salesforce_org_members/${
            args.salesforce_org_member_id
          }/quotas/${args.quota_id}`,
          args,
        ).then(e => e.json())
      },
    },
  }

  static flowcharts = {
    generate: async (args: any) => {
      return Api.post(`${getUrl()}/flowcharts/generate`, args).then(e =>
        e.json(),
      )
    },
    update: async (args: any) => {
      return Api.post(`${getUrl()}/flowcharts/update`, args).then(e => e.json())
    },
    convert: async (args: any) => {
      return Api.post(`${getUrl()}/flowcharts/convert`, args).then(e =>
        e.json(),
      )
    },
  }

  static marketplace = {
    fetch: async (args: { itemType?: 'Alert' | 'Flow' | 'Report' }) => {
      return Api.get(`${getUrl()}/marketplace_items/items`, args).then(e =>
        e.json(),
      )
    },
    deploy: async (args: {
      itemId: string
      itemCustomization?: string
      reportFolder?: string
    }) => {
      return Api.post(`${getUrl()}/marketplace_items/deploy`, args, false).then(
        e => e.json(),
      )
    },
    deploymentStatus: async (args: { itemId: string; deployId: string }) => {
      return Api.get(
        `${getUrl()}/marketplace_items/deployment_status`,
        args,
      ).then(e => e.json())
    },
    removeMarketplaceItem: async (args: { itemId: string }) => {
      return Api.delete(`${getUrl()}/marketplace_items/remove`, args).then(e =>
        e.json(),
      )
    },
  }

  static scheduledRuns = {
    combinedReports: {
      createRun: async (args: CreateCombinedReportScheduledRunArgs) => {
        return Api.post(
          `${getUrl()}/user_job/create_combined_report`,
          args,
        ).then(e => e.json())
      },
      enableRun: async (args: CombinedReportRunToggleArgs) => {
        return Api.put(
          `${getUrl()}/user_job/enable_combined_report`,
          args,
        ).then(e => e.json())
      },
      disableRun: async (args: CombinedReportRunToggleArgs) => {
        return Api.put(
          `${getUrl()}/user_job/disable_combined_report`,
          args,
        ).then(e => e.json())
      },
      deleteRun: async (args: DeleteCombinedReportArgs) => {
        return Api.delete(
          `${getUrl()}/user_job/delete_combined_report`,
          args,
        ).then(e => e.json())
      },
      findRun: async (args: CombinedReportRunToggleArgs) => {
        return Api.get(`${getUrl()}/user_job/find_combined_report`, args).then(
          e => e.json(),
        )
      },
    },
    rollups: {
      createRun: async (args: CreateRollupScheduledRunArgs) => {
        return Api.post(`${getUrl()}/user_job/create_rollup`, args).then(e =>
          e.json(),
        )
      },
      fetchRun: async (args: FetchRollupScheduledRunArgs) => {
        return Api.get(`${getUrl()}/user_job/fetch_rollup`, args).then(e =>
          e.json(),
        )
      },
    },
    forecasts: {
      create: async (args: {
        teamId: string
        cron: string
        applyAll: boolean
      }) => {
        return Api.post(
          `${getUrl()}/user_job/create_forecast_reminder`,
          args,
        ).then(e => e.json())
      },
    },
  }

  static cachedReport = {
    fetch: async (args: any) => {
      return Api.get(`${getUrl()}/cached_report/${args.id}`, args).then(e =>
        e.json(),
      )
    },
  }

  static salesforce = {
    fieldUsage: {
      exportUnusedFields: async (args: any) => {
        return Api.get(
          `${getUrl()}/salesforce/field_utilization/export_unused_fields`,
          args,
        ).then(e => e?.blob())
      },
    },
    fieldRecordUsage: {
      exportUnusedFields: async (args: any) => {
        return Api.get(
          `${getUrl()}/salesforce/field_utilization/export_unused_fields_in_records`,
          args,
        ).then(e => e?.blob())
      },
    },
    activities: {
      getLatestActivity: async (args: GetActivityArgs) => {
        return Api.get(`${getUrl()}/activities`, args).then(e => e.json())
      },
      updateActivity: async (args: any) => {
        return Api.patch(
          `${getUrl()}/activities/${args.activity_id}`,
          args,
        ).then(e => e.json())
      },
    },
    dealStages: {
      getDealStages: async (args: any) => {
        return Api.get(`${getUrl()}/salesforce/deal_stages`, args).then(e =>
          e.json(),
        )
      },
    },
    deals: {
      getDeals: async (args: any) => {
        return Api.getV2(`${getUrl()}/salesforce/deals`, args).then(e =>
          e.json(),
        )
      },
      getDeal: async (args: any) => {
        return Api.get(`${getUrl()}/salesforce/deals/${args.id}`, args).then(
          e => e.json(),
        )
      },
      notes: {
        getNotes: async (dealId: string, args: any) => {
          return Api.getV2(
            `${getUrl()}/salesforce/deals/${dealId}/notes`,
            args,
          ).then(e => e.json())
        },
        createNote: async (dealId: string, args: any) => {
          return Api.post(
            `${getUrl()}/salesforce/deals/${dealId}/notes`,
            args,
          ).then(e => e.json())
        },
        updateNote: async (dealId: string, noteId: string, args: any) => {
          return Api.patch(
            `${getUrl()}/salesforce/deals/${dealId}/notes/${noteId}`,
            args,
          ).then(e => e.json())
        },
        deleteNote: async (dealId: string, noteId: string, args: any) => {
          return Api.delete(
            `${getUrl()}/salesforce/deals/${dealId}/notes/${noteId}`,
            args,
          ).then(e => e.json())
        },
      },
    },
    users: {
      getUsers: async (args: any) => {
        return Api.get(`${getUrl()}/salesforce/users`, args).then(e => e.json())
      },
    },
    customFields: {
      getCustomFields: async (args: GetCustomFieldsArgs) => {
        return Api.get(`${getUrl()}/salesforce/custom_fields`, args).then(e =>
          e.json(),
        )
      },
      bulkCreateCustomFields: async (args: BulkCreateCustomFieldsArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/custom_fields/bulk_create`,
          args,
        ).then(e => e.json())
      },
      deploymentStatus: async (args: GetBulkFieldsDeploymentStatus) => {
        return Api.post(
          `${getUrl()}/salesforce/custom_fields/deployment_status`,
          args,
        ).then(e => e.json())
      },
      deleteCustomField: async (args: DeleteCustomFieldArgs) => {
        return Api.delete(
          `${getUrl()}/salesforce/custom_fields/delete_custom_field`,
          args,
        ).then(e => e.json())
      },
      fieldReferences: async (args: {
        fieldName: string
        objectName: string
        isDemoEnv: boolean
      }) => {
        return Api.get(
          `${getUrl()}/salesforce/custom_fields/field_references`,
          args,
        ).then(e => e.json())
      },
      picklistValueReferences: async (args: {
        fieldName: string
        picklistValue: string
        objectName: string
      }) => {
        return Api.get(
          `${getUrl()}/salesforce/custom_fields/picklist_references`,
          args,
        ).then(e => e.json())
      },
      updateReferences: async (args: {
        newField: string
        oldField: string
        references: string
        objectName: string
        replaceValues?: boolean
        fieldName?: string
        isPicklistReplace?: boolean
      }) => {
        return Api.patch(
          `${getUrl()}/salesforce/custom_fields/update_references`,
          args,
        ).then(e => e.json())
      },
    },
    fields: {
      recommendedFields: async (args?: RecommendedFieldsArgs) => {
        return Api.get(
          `${getUrl()}/salesforce/field_recommendations`,
          args,
        ).then(e => e.json())
      },
    },
    forecasts: {
      createForecast: async (args?: CreateForecastArgs) => {
        return Api.post(`${getUrl()}/salesforce/forecasts`, args).then(e =>
          e.json(),
        )
      },
      explain: async (args?: ExplainForecastArgs) => {
        return Api.post(`${getUrl()}/salesforce/forecasts/explain`, args).then(
          e => e.json(),
        )
      },
      scenario: async (args?: ForecastScenarioArgs) => {
        return Api.post(`${getUrl()}/salesforce/forecasts/scenario`, args).then(
          e => e.json(),
        )
      },
    },
    objectRecommendations: {
      getObjectRecommendations: async (args?: GetObjectRecommendationsArgs) => {
        return Api.get(
          `${getUrl()}/salesforce/object_recommendations`,
          args,
        ).then(e => {
          if (FIXTURE_ENV) {
            return Promise.resolve({})
          } else {
            return e.json()
          }
        })
      },
      featuredObjects: async (args?: FeatureObjectsArgs) => {
        return Api.get(`${getUrl()}/salesforce/featured_objects`, args).then(
          e => {
            if (FIXTURE_ENV) {
              return Promise.resolve(featuredObjects)
            } else {
              return e.json()
            }
          },
        )
      },
    },
    records: {
      createRecord: async (args: CreateRecordArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/data/create_record`,
          args,
          false,
        ).then(e => e.json())
      },
    },
    recordSnapshots: {
      getSnapshots: async (args?: GetSnapshotArgs) => {
        return Api.get(`${getUrl()}/salesforce/record_snapshots`, args).then(
          e => e.json(),
        )
      },
      exportSnapshots: async (args?: GetSnapshotArgs) => {
        return Api.get(
          `${getUrl()}/salesforce/record_snapshots/export`,
          args,
        ).then(e => e.blob())
      },
    },
    objects: {
      createObject: async (args: CreateObjectArgs) => {
        return Api.post(`${getUrl()}/salesforce/objects`, args).then(e =>
          e.json(),
        )
      },
      getObjects: async (args?: GetObjectsArgs) => {
        return Api.get(`${getUrl()}/salesforce/objects`, args).then(e =>
          e.json(),
        )
      },
      getObjectsMetadata: async (args?: GetObjectsMetadataArgs) => {
        return Api.get(`${getUrl()}/salesforce/objects/${args?.id}`, args).then(
          e => e.json(),
        )
      },
      generateObject: async (args: GenerateObjectArgs) => {
        return Api.post(`${getUrl()}/salesforce/objects/generate`, args).then(
          e => {
            return e.json()
          },
        )
      },
      updateObject: async (args: UpdateObjectArgs) => {
        return Api.patch(
          `${getUrl()}/salesforce/objects/${args?.id}`,
          args,
        ).then(e => e.json())
      },
      fetchMemberStatus: async () => {
        return Api.get(`${getUrl()}/salesforce/objects/member_status`).then(e =>
          e.json(),
        )
      },
      deleteObject: async (args: DeleteObjectArgs) => {
        return Api.delete(
          `${getUrl()}/salesforce/objects/delete_custom_object`,
          args,
        ).then(e => e.json())
      },
      createRecordType: async (args: CreateRecordTypeArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/objects/create_record_type`,
          args,
        ).then(e => e.json())
      },
      fetchRecordTypes: async (args: { objectName: string }) => {
        return Api.get(
          `${getUrl()}/salesforce/objects/fetch_record_types`,
          args,
        ).then(e => e.json())
      },

      objectName: {
        customFields: {
          getCustomField: async (args: GetCustomFieldArgs) => {
            return Api.get(
              `${getUrl()}/salesforce/objects/${
                args.objectName
              }/custom_fields/${args.customFieldName}`,
              args,
            ).then(e => e.json())
          },
          getCustomFieldMetadata: async (
            args: GetCustomFieldDefinitionArgs,
          ) => {
            return Api.get(
              `${getUrl()}/salesforce/objects/${
                args.objectName
              }/custom_fields/field_definitions`,
              args,
            ).then(e => e.json())
          },
          getAllLookupFilters: async (args: GetAllLookupFiltersProps) => {
            return Api.get(
              `${getUrl()}/salesforce/objects/${
                args.objectName
              }/lookup_filters`,
              args,
            ).then(e => e.json())
          },
          createCustomField: async (args: CreateCustomFieldArgs) => {
            return Api.post(
              `${getUrl()}/salesforce/objects/${args.objectName}/custom_fields`,
              args,
            ).then(e => e.json())
          },
          editCustomField: async (args: EditCustomFieldArgs) => {
            return Api.put(
              `${getUrl()}/salesforce/objects/${
                args.objectName
              }/custom_fields/${args.fieldName}`,
              args,
            ).then(e => e.json())
          },
          editDescription: async (args: any) => {
            return Api.patch(
              `${getUrl()}/salesforce/objects/${
                args.objectName
              }/custom_fields/update_description`,
              args,
            ).then(e => e.json())
          },
          deleteCustomField: async (args: DeleteCustomFieldArgs) => {
            return Api.delete(
              `${getUrl()}/salesforce/objects/${
                args.objectName
              }/custom_fields/${args.fieldName}`,
              args,
            ).then(e => {
              return { status: e.status, statusText: e.statusText }
            })
          },
          generateFormula: async (args: GenerateCustomFieldArgs) => {
            return Api.post(
              `${getUrl()}/salesforce/objects/${
                args.objectName
              }/custom_fields/formula`,
              args,
            ).then(e => {
              return e.json()
            })
          },
          generateFieldMetaText: async (args: GenerateCustomFieldArgs) => {
            return Api.post(
              `${getUrl()}/salesforce/objects/${
                args.objectName
              }/custom_fields/generate`,
              args,
            ).then(e => {
              return e.json()
            })
          },
          customFieldRecommendations: async (
            args: CustomFieldRecommendationsArgs,
          ) => {
            return Api.post(
              `${getUrl()}/salesforce/objects/${
                args.objectName
              }/custom_fields/recommendations`,
              args,
            ).then(e => {
              if (FIXTURE_ENV) {
                return Promise.resolve(fieldReccomendations)
              } else {
                return e.json()
              }
            })
          },
        },
        fields: {
          getFields: async (args: GetFieldsArgs) => {
            return Api.get(
              `${getUrl()}/salesforce/objects/${args.objectName}/fields`,
              args,
            ).then(e => e.json())
          },
          fetchRelatedObjectFields: async (args: GetFieldsArgs) => {
            return Api.get(
              `${getUrl()}/salesforce/objects/${
                args.objectName
              }/fields/related_objects`,
              args,
            ).then(e => e.json())
          },
          getGrandparentFields: async (args: GetFieldsArgs) => {
            // Define API endpoint with parameters for relationship fields
            return Api.get(
              `${getUrl()}/salesforce/objects/fields/get_grandparent_fields`,
              args,
            ).then(e => e.json())
          },
          getAllUpdateableFields: async (args: GetFieldsArgs) => {
            // Define API endpoint with parameters for relationship fields
            return Api.get(
              `${getUrl()}/salesforce/objects/fields/get_updateable_fields`,
              args,
            ).then(e => e.json())
          },
          getDependentFields: async (args: any) => {
            return Api.get(
              `${getUrl()}/salesforce/objects/fields/dependent_picklist_values_for_field`,
              args,
            ).then(e => e.json())
          },
        },
        rollupFields: {
          createRollup: async (args: CreateRollupFieldsArgs) => {
            return Api.post(
              `${getUrl()}/salesforce/objects/${
                args.objectName
              }/rollup_fields/create_rollup`,
              args,
            ).then(e => e.json())
          },
          updateRollupValue: async (args: UpdateRollupValueArgs) => {
            return Api.post(
              `${getUrl()}/salesforce/objects/${
                args.objectName
              }/rollup_fields/update_rollup_value`,
              args,
            ).then(e => e.json())
          },
          fetchRollup: async (args: FetchRollupConfigurationArgs) => {
            return Api.get(
              `${getUrl()}/salesforce/objects/${
                args.objectName
              }/rollup_fields/fetch_rollup`,
              args,
            ).then(e => e.json())
          },
          updateRollup: async (args: UpdateRollupConfigurationArgs) => {
            return Api.put(
              `${getUrl()}/salesforce/objects/${
                args.objectName
              }/rollup_fields/update_rollup`,
              args,
            ).then(e => e.json())
          },
        },
        pageLayouts: {
          getPageLayouts: async (args: GetPageLayoutArgs) => {
            return Api.get(
              `${getUrl()}/salesforce/objects/${args.objectName}/page_layouts`,
              args,
            ).then(e => e.json())
          },
          getLayoutsForRecordTypes: async (args: GetPageLayoutArgs) => {
            return Api.get(
              `${getUrl()}/salesforce/objects/${
                args.objectName
              }/page_layouts/layout_for_record_type`,
              args,
            ).then(e => e.json())
          },
          getPicklistValues: async (args: GetPicklistValuesArgs) => {
            return Api.get(
              `${getUrl()}/salesforce/objects/${
                args.objectName
              }/page_layouts/picklist_values_for_field_on_record_type`,
              args,
            ).then(e => e.json())
          },
        },
      },
    },
    relatedReports: {
      generate: async (args: GenerateRelatedReportArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/related_reports/generate`,
          args,
        ).then(e => e.json())
      },
    },
    reports: {
      dryRunReport: async (args: DryRunReportArgs) => {
        return Api.post(`${getUrl()}/salesforce/reports/dry_run_report`, args)
          .then(e => e.json())
          .then(data => data)
      },
      recorrectFormulaQuery: async (args: RecorrectArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/reports/recorrect_formula_query`,
          args,
        )
          .then(e => e.json())
          .then(data => data)
      },
      generateGroupings: async (args: GenerateGroupingsArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/reports/generate_groupings`,
          args,
        )
          .then(e => e.json())
          .then(data => data)
      },
      generateCustomFormula: async (args: GenerateCustomFieldFormulaArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/reports/custom_formula`,
          args,
        ).then(e => {
          return e.json()
        })
      },
      validateFieldNames: async (params: ValidateFieldNamesParams) => {
        return Api.post(`${getUrl()}/salesforce/reports/validate_field_names`, {
          field_names: params.fieldNames,
        })
          .then(e => e.json())
          .then(data => data)
      },
      validateFunctions: async (params: ValidateFunctionsParams) => {
        return Api.post(`${getUrl()}/salesforce/reports/validate_functions`, {
          functions: params.functions,
        })
          .then(e => e.json())
          .then(data => data)
      },
      createReport: async (args: CreateReportArgs) => {
        return Api.post(`${getUrl()}/salesforce/reports`, args).then(e => {
          if (FIXTURE_ENV) {
            return Promise.resolve(reports)
          } else {
            return e.json()
          }
        })
      },
      exportReport: async (args: CreateReportArgs) => {
        return Api.post(`${getUrl()}/salesforce/reports/export`, args).then(e =>
          e.text(),
        )
      },
      deployReport: async (args: DeployReportArgs) => {
        return Api.post(`${getUrl()}/salesforce/reports/deploy`, args).then(e =>
          e.json(),
        )
      },
      deleteDeployedReport: async (args: DeleteReportArgs) => {
        return Api.delete(
          `${getUrl()}/salesforce/reports/delete_deployed_report`,
          args,
        ).then(e => e.json())
      },
      deployReportWithFormula: async (args: DeployReportWithFormulaArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/reports/deploy_with_formula`,
          args,
        ).then(e => e.json())
      },
      chartProperties: async (args: ReportChartPropertiesArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/reports/chart_properties`,
          args,
        ).then(e => e.json())
      },
      details: async (args: ReportDetailsArgs) => {
        return Api.post(`${getUrl()}/salesforce/reports/details`, args).then(
          e => {
            if (FIXTURE_ENV) {
              return Promise.resolve(reportDetail)
            } else {
              return e.json()
            }
          },
        )
      },
      getReportDeploymentStatus: async (args: GetReportDeploymentStatus) => {
        return Api.get(
          `${getUrl()}/salesforce/reports/deployment_status`,
          args,
        ).then(e => e.json())
      },
      // Combined Reports
      fetchAllReports: async () => {
        return Api.get(`${getUrl()}/salesforce/reports/fetch_all`, {}).then(e =>
          e.json(),
        )
      },
      fetchAllColumns: async (args: FetchColumnsArgs) => {
        // Change to get once BE changes are made
        return Api.get(
          `${getUrl()}/salesforce/reports/fetch_columns`,
          args,
        ).then(e => e.json())
      },
      createCombinedReports: async (args: CreateCombinedReportArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/reports/create_combined_report`,
          args,
        ).then(e => e.json())
      },
      deleteCombinedReport: async (args: DeleteCombinedReportArgs) => {
        return Api.delete(
          `${getUrl()}/salesforce/reports/delete_report`,
          args,
        ).then(e => e.json())
      },
      fetchAllCombinedReports: async () => {
        return Api.get(
          `${getUrl()}/salesforce/reports/fetch_combined_reports`,
        ).then(e => e.json())
      },
      fetchCombinedReportById: async (args: FetchCombinedReportByIdArgs) => {
        return Api.get(
          `${getUrl()}/salesforce/reports/fetch_combined_report`,
          args,
        ).then(e => e.json())
      },
      saveReportChanges: async (args: SaveReportChangesArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/reports/save_changes`,
          args,
        ).then(e => e.json())
      },
      createColumn: async (args: CreateCustomColumnArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/reports/create_column`,
          args,
        ).then(e => e.json())
      },
      generateColumn: async (args: GenerateColumnArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/reports/generate_column`,
          args,
        ).then(e => e.json())
      },
      getReportRun: async (args: GetReportRunArgs) => {
        return Api.get(
          `${getUrl()}/salesforce/reports/get_report_run`,
          args,
        ).then(e => e.json())
      },
    },
    reportFolders: {
      getReportFolders: async (args: GetReportFolders) => {
        return Api.get(`${getUrl()}/salesforce/report_folders`, args).then(
          e => {
            return e.json()
          },
        )
      },
    },
    reportTypes: {
      createReportType: async (args: CreateReportType) => {
        return Api.post(`${getUrl()}/salesforce/report_types`, args).then(e =>
          e.json(),
        )
      },
    },
    reportCreationLogs: {
      createReportCreationLog: async (args: CreateReportCreationLogsArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/report_creation_logs`,
          args,
        ).then(e => e.json())
      },
      getReportCreationLog: async (args: GetReportCreationLogsArgs) => {
        return Api.get(
          `${getUrl()}/salesforce/report_creation_logs`,
          args,
        ).then(e => e.json())
      },
      showReportCreationLog: async (id: string) => {
        return Api.get(
          `${getUrl()}/salesforce/report_creation_logs/${id}`,
        ).then(e => e.json())
      },
    },
    reportQueries: {
      generateReportQuery: async (args: GenerateReportQueryArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/report_queries/generate`,
          args,
        ).then(e => e.json())
      },
      getFolders: async () => {
        return Api.get(`${getUrl()}/salesforce/report_queries/folders`).then(
          e => {
            if (FIXTURE_ENV) {
              return Promise.resolve(folders)
            } else {
              return e.json()
            }
          },
        )
      },
      crossFilterObjects: async (args: CrossFilterObjectsArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/report_queries/cross_filter_objects`,
          args,
        ).then(e => {
          if (!e.ok) {
            return { success: false }
          } else {
            return e.json()
          }
        })
      },
      createReportQuery: async (args: CreateReportQueryArgs) => {
        return Api.post(`${getUrl()}/salesforce/report_queries`, args).then(
          e => {
            return { status: e.status, statusText: e.statusText }
          },
        )
      },
      updateReportQuery: async (id: string, args: UpdateReportQueryArgs) => {
        return Api.patch(
          `${getUrl()}/salesforce/report_queries/${id}`,
          args,
        ).then(e => {
          return { status: e.status, statusText: e.statusText }
        })
      },
      deleteReportQuery: async (args: DeleteReportQueryArgs) => {
        return Api.delete(
          `${getUrl()}/salesforce/report_queries/${args.id}`,
          args,
        ).then(e => {
          return { status: e.status, statusText: e.statusText }
        })
      },
      getReportQueries: async (args: GetReportArgs) => {
        return Api.get(`${getUrl()}/salesforce/report_queries`, args).then(
          e => {
            if (FIXTURE_ENV) {
              return Promise.resolve(reportsQuery)
            } else {
              return e.json()
            }
          },
        )
      },
    },
    data: {
      queryRecords: async (args: any) => {
        return Api.get(`${getUrl()}/salesforce/data/query_records`, args).then(
          e => e.json(),
        )
      },
      queryRecordId: async (args: {
        objectName: string
        fieldName: string
        fieldValue: string
      }) => {
        return Api.get(
          `${getUrl()}/salesforce/data/query_record_id`,
          args,
        ).then(e => e.json())
      },
      queryRelatedRecords: async (args: any) => {
        return Api.get(
          `${getUrl()}/salesforce/data/query_related_records`,
          args,
        ).then(e => e.json())
      },
      fetchAllRecords: async (args: {
        object_name: string
        filters?: string
        relative_percent_filters?: any[]
      }) => {
        return Api.get(
          `${getUrl()}/salesforce/data/fetch_all_records`,
          args,
        ).then(e => e.json())
      },
      queryRelatedActivity: async (args: any) => {
        return Api.get(
          `${getUrl()}/salesforce/data/query_related_activity`,
          args,
        ).then(e => e.json())
      },
      searchRecords: async (args: any) => {
        return Api.get(`${getUrl()}/salesforce/data/search_records`, args).then(
          e => e.json(),
        )
      },
      recordCount: async (args: any) => {
        return Api.get(`${getUrl()}/salesforce/data/record_count`, args).then(
          e => e.json(),
        )
      },
      updateRecord: async (args: any) => {
        return Api.patch(
          `${getUrl()}/salesforce/data/update_record`,
          args,
          false,
        ).then(e => {
          return { status: e.status, statusText: e.statusText }
        })
      },
      demoBatchInsert: async (args: any) => {
        return Api.post(
          `${getUrl()}/salesforce/data/demo_batch_insert`,
          args,
        ).then(e => e.json())
      },
      batchUpdate: async (args: any) => {
        return Api.post(
          `${getUrl()}/salesforce/data/batch_update`,
          args,
          false,
        ).then(e => e.json())
      },
      revertUpdates: async (args: any) => {
        return Api.post(
          `${getUrl()}/salesforce/data/revert_updates`,
          args,
          false,
        ).then(e => e.json())
      },
      bulkUpdate: async (args: DataBulkUpdateArgs) => {
        return Api.post(`${getUrl()}/salesforce/data/bulk_update`, args).then(
          e => e.json(),
        )
      },
      bulkReplace: async (args: {
        oldField: string
        newField: string
        objectName: string
      }) => {
        return Api.post(`${getUrl()}/salesforce/data/bulk_replace`, args).then(
          e => e.json(),
        )
      },
      attributeChanges: async (args: AttributeChangesArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/data/attribute_changes`,
          args,
        ).then(e => {
          if (FIXTURE_ENV) {
            return Promise.resolve(attributeChanges)
          } else {
            return e.json()
          }
        })
      },
      migrationFunction: async (args: any) => {
        return Api.post(
          `${getUrl()}/salesforce/data/migration_function`,
          args,
        ).then(e => {
          return e.json()
        })
      },
      jobStatus: async (args: GetJobStatusArgs) => {
        return Api.get(`${getUrl()}/salesforce/data/job_status`, args).then(e =>
          e.json(),
        )
      },
    },
    apexCode: {
      edit: async (args: any) => {
        return Api.post(`${getUrl()}/salesforce/apex_code/edit`, args).then(e =>
          e.json(),
        )
      },
      generate: async (args: any) => {
        return Api.post(`${getUrl()}/salesforce/apex_code/generate`, args).then(
          e => e.json(),
        )
      },
      analyze: async (args: any) => {
        return Api.post(`${getUrl()}/salesforce/apex_code/analyze`, args).then(
          e => e.json(),
        )
      },
    },
    apexTests: {
      generateList: async (args: any) => {
        return Api.post(
          `${getUrl()}/salesforce/apex_tests/generate_list`,
          args,
        ).then(e => e.json())
      },
      generate: async (args: any) => {
        return Api.post(
          `${getUrl()}/salesforce/apex_tests/generate`,
          args,
        ).then(e => e.json())
      },
    },
    apexTriggers: {
      generate: async (args: any) => {
        return Api.post(
          `${getUrl()}/salesforce/apex_triggers/generate`,
          args,
        ).then(e => e.json())
      },
      write: async (args: any) => {
        return Api.post(
          `${getUrl()}/salesforce/apex_triggers/write`,
          args,
        ).then(e => e.json())
      },
      writeTest: async (args: any) => {
        return Api.post(
          `${getUrl()}/salesforce/apex_triggers/write_test`,
          args,
        ).then(e => e.json())
      },
      getAutomationTriggers: async () => {
        return Api.get(`${getUrl()}/salesforce/apex_triggers/automations`).then(
          e => e.json(),
        )
      },
    },
    profiles: {
      getProfiles: async (args: any) => {
        return Api.get(`${getUrl()}/salesforce/profiles`, args).then(e =>
          e.json(),
        )
      },
      updateFieldAccess: async (args: UpdateFieldAccessArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/profiles/update_field_access`,
          args,
        ).then(e => e.json())
      },
    },
    apexClasses: {
      relevantObjects: async (args: GetApexClassRelevantObjectsArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/apex_classes/relevant_objects`,
          args,
        ).then(e => e.json())
      },
      generateApexClass: async (args: GenerateApexClassArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/apex_classes/generate`,
          args,
        ).then(e => e.json())
      },
      interpret: async (args: InterpretApexClassArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/apex_classes/interpret`,
          args,
        ).then(e => e.json())
      },
      retrieve: async (args: RetrieveApexClassArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/apex_classes/retrieve`,
          args,
        ).then(e => e.json())
      },
      getApexClasses: async (args: GetApexClassesArgs) => {
        return Api.get(`${getUrl()}/salesforce/apex_classes`, args).then(e =>
          e.json(),
        )
      },
      deployApexClass: async (args: DeployApexClassArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/apex_classes/deploy`,
          args,
        ).then(e => e.json())
      },
      deployApexTriggerVersion: async (args: DeployApexTriggerVersionArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/apex_classes/deploy_automation_trigger_version`,
          args,
        ).then(e => e.json())
      },
      getApexClassDeploymentStatus: async (
        args: GetApexClassDeploymentStatusArgs,
      ) => {
        return Api.get(
          `${getUrl()}/salesforce/apex_classes/deployment_status`,
          args,
        ).then(e => e.json())
      },
      deleteApexTrigger: async (args: DeleteApexTriggerArgs) => {
        return Api.delete(
          `${getUrl()}/salesforce/apex_classes/delete_apex_trigger`,
          args,
        ).then(e => e.json())
      },
    },
    validationRules: {
      createValidationRule: async (args: CreateValidationRuleArgs) => {
        return Api.post(`${getUrl()}/salesforce/validation_rules`, args).then(
          e => e.json(),
        )
      },
      deleteValidationRule: async (args: DeleteValidationRuleArgs) => {
        return Api.delete(
          `${getUrl()}/salesforce/objects/${args.objectName}/validation_rules/${
            args.name
          }`,
          args,
        ).then(e => e.json())
      },
      getObjectValidationRules: async (args: GetObjectValidationRulesArgs) => {
        return Api.get(`${getUrl()}/salesforce/validation_rules`, args).then(
          e => e.json(),
        )
      },
      updateValidationRules: async (args: UpdateValidationRuleArgs) => {
        return Api.put(
          `${getUrl()}/salesforce/validation_rules/${args.id}`,
          args,
        ).then(e => e.json())
      },
      generateValidationRule: async (args: GenerateValidationRuleArgs) => {
        return Api.post(
          `${getUrl()}/salesforce/validation_rules/generate`,
          args,
        ).then(e => e.json())
      },
      recommendedValidationRules: async (
        args?: RecommendedValidationRulessArgs,
      ) => {
        return Api.get(
          `${getUrl()}/salesforce/validation_rule_recommendations`,
          args,
        ).then(e => {
          if (FIXTURE_ENV) {
            return Promise.resolve(validationRuleReccomendations)
          } else {
            return e.json()
          }
        })
      },
      validationRuleSearch: async (args: { keyword: string }) => {
        return Api.get(
          `${getUrl()}/salesforce/validation_rules/validation_rule_search`,
          args,
        ).then(e => e.json())
      },
      getValidationRule: async (args: { id: string }) => {
        return Api.get(
          `${getUrl()}/salesforce/validation_rules/${args.id}`,
          args,
        ).then(e => e.json())
      },
    },
  }

  static pipelineAnalytics = {
    getPipelineAnalytics: async () => {
      return Api.get(`${getUrl()}/pipeline_analytics`).then(e => e.json())
    },
  }

  static async login(url: string, args: unknown, convertKeys: boolean = true) {
    const formattedParams = convertKeys
      ? Api.convertKeysToSnakeCase(args)
      : args

    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.fetchJWT(),
      },
      body: JSON.stringify(formattedParams),
    }).then(response => {
      if (
        (response.status === 401 || response.status === 403) &&
        window.location.pathname !== '/signout' &&
        window.location.pathname !== '/login'
      ) {
        window.location.href = '/signout'
      }
      return response
    })
  }

  static async post(url: string, args: unknown, convertKeys: boolean = true) {
    const jwt = this.fetchJWT()
    if (this.validateJWT(jwt) && window.location.pathname !== '/login') {
      window.location.href = '/login'
    }
    const formattedParams = convertKeys
      ? Api.convertKeysToSnakeCase(args)
      : args

    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: jwt,
      },
      body: JSON.stringify(formattedParams),
    }).then(response => {
      if (
        (response.status === 401 || response.status === 403) &&
        window.location.pathname !== '/signout' &&
        window.location.pathname !== '/login'
      ) {
        window.location.href = '/signout'
        // return Promise.reject(new Error('Unauthorized or Forbidden'))
      }
      return response
    })
  }

  static async patch(url: string, args: unknown, convertKeys: boolean = true) {
    const jwt = this.fetchJWT()
    if (this.validateJWT(jwt) && window.location.pathname !== '/login') {
      window.location.href = '/login'
    }
    const formattedParams = convertKeys
      ? Api.convertKeysToSnakeCase(args)
      : args

    return fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: jwt,
      },
      body: JSON.stringify(formattedParams),
    }).then(response => {
      if (
        (response.status === 401 || response.status === 403) &&
        window.location.pathname !== '/signout' &&
        window.location.pathname !== '/login'
      ) {
        window.location.href = '/signout'
        // return Promise.reject(new Error('Unauthorized or Forbidden'))
      }
      return response
    })
  }

  static async put(url: string, args: unknown) {
    const jwt = this.fetchJWT()
    if (this.validateJWT(jwt) && window.location.pathname !== '/login') {
      window.location.href = '/login'
    }
    const formattedParams = Api.convertKeysToSnakeCase(args)

    return fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: jwt,
      },
      body: JSON.stringify(formattedParams),
    }).then(response => {
      if (
        (response.status === 401 || response.status === 403) &&
        window.location.pathname !== '/signout' &&
        window.location.pathname !== '/login'
      ) {
        window.location.href = '/signout'
        // return Promise.reject(new Error('Unauthorized or Forbidden'))
      }
      return response
    })
  }

  static async delete(url: string, params?: unknown) {
    const jwt = this.fetchJWT()
    if (this.validateJWT(jwt) && window.location.pathname !== '/login') {
      window.location.href = '/login'
    }
    const formattedParams = Api.convertKeysToSnakeCase(params)

    return fetch(`${url}?${qs.stringify(formattedParams)}`, {
      method: 'DELETE',
      headers: {
        Authorization: jwt,
      },
    }).then(response => {
      if (
        (response.status === 401 || response.status === 403) &&
        window.location.pathname !== '/signout' &&
        window.location.pathname !== '/login'
      ) {
        window.location.href = '/signout'
        // return Promise.reject(new Error('Unauthorized or Forbidden'))
      }
      return response
    })
  }

  static validateJWT(jwt: string) {
    try {
      if (!jwt) {
        return true
      }

      const expiry = JSON.parse(atob(jwt.split('.')[1])).exp
      const currentDate = new Date().getTime()
      return Math.floor(currentDate / 1000) >= expiry
    } catch (error) {
      return true
    }
  }

  static fetchJWT() {
    return localStorage.getItem('jwt') as string
  }

  static async setJWTHeader(jwt: string) {
    localStorage.setItem('jwt', jwt)
  }

  static async get(url: string, params?: unknown) {
    const jwt = this.fetchJWT()
    if (this.validateJWT(jwt) && window.location.pathname !== '/login') {
      window.location.href = '/login'
    }
    const formattedParams = Api.convertKeysToSnakeCase(params)

    return fetch(`${url}?${qs.stringify(formattedParams)}`, {
      method: 'GET',
      headers: {
        Authorization: jwt,
        Accept: 'application/xml',
      },
    }).then(response => {
      if (
        (response.status === 401 || response.status === 403) &&
        window.location.pathname !== '/signout' &&
        window.location.pathname !== '/login'
      ) {
        window.location.href = '/signout'
        // return Promise.reject(new Error('Unauthorized or Forbidden'))
      }
      return response
    })
  }

  static async getV2(url: string, params?: unknown) {
    const jwt = this.fetchJWT()
    if (this.validateJWT(jwt) && window.location.pathname !== '/login') {
      window.location.href = '/login'
    }
    const queryString = Api.getQueryString(params)

    return fetch(`${url}${queryString}`, {
      method: 'GET',
      headers: {
        Authorization: jwt,
      },
    }).then(response => {
      if (
        (response.status === 401 || response.status === 403) &&
        window.location.pathname !== '/signout' &&
        window.location.pathname !== '/login'
      ) {
        window.location.href = '/signout'
        // return Promise.reject(new Error('Unauthorized or Forbidden'))
      }
      return response
    })
  }

  static async me() {
    return this.get(`${getUrl()}/me`).then(e => e.json())
  }

  static async switchView(args: any) {
    return this.post(`${getUrl()}/switch_view`, args).then(e => e.json())
  }

  static async createSession(args: SessionPostArgs) {
    return Api.login(`${getUrl()}/sessions`, args).then(e => e.json())
  }

  static async updateAccount(args: UpdateAccountArgs) {
    return Api.patch(`${getUrl()}/account`, args).then(e => {
      return e.json()
    })
  }

  static async createWebsocketToken(args: any) {
    return Api.post(`${getUrl()}/websockets/token`, args).then(e => {
      return e.json()
    })
  }

  static async signout() {
    return Api.get(`${getUrl()}/signout`)
  }

  private static camelToSnake(str: string): string {
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
  }

  private static convertKeysToSnakeCase(obj: any): any {
    if (typeof obj !== 'object' || obj === null) {
      return obj
    }

    if (Array.isArray(obj)) {
      return obj.map(item => Api.convertKeysToSnakeCase(item))
    }
    return Object.entries(obj).reduce(
      (acc: Args, [key, value]: [string, any]) => {
        // If the key is 'custom_actions' and it's an array, we want to iterate through each custom action
        if (key === 'custom_actions' && Array.isArray(value)) {
          acc[key] = value.map((customAction: any) => {
            // Make sure we exclude the 'values' object within each custom action from being converted
            if (
              customAction.values &&
              typeof customAction.values === 'object'
            ) {
              return {
                ...customAction,
                // Keep the 'values' keys as they are
                values: { ...customAction.values },
              }
            }
            return customAction // Return the custom action unchanged
          })
        } else {
          // Convert the rest of the object to snake_case
          const snakeKey = Api.camelToSnake(key)
          acc[snakeKey] = Api.convertKeysToSnakeCase(value)
        }
        return acc
      },
      {},
    )
  }

  private static getQueryString(params: any) {
    const esc = encodeURIComponent
    const parts = [] as any

    const add = (key: any, value: any) => {
      if (Array.isArray(value)) {
        value.forEach(item => parts.push(`${esc(key)}[]=${esc(item)}`))
      } else {
        parts.push(`${esc(key)}=${esc(value)}`)
      }
    }

    Object.keys(params).forEach(key => {
      add(key, params[key])
    })

    return parts.length > 0 ? `?${parts.join('&')}` : ''
  }
}

export default Api
